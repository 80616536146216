body {
  margin: 0;
  font-family: 'Alex Brush', cursive;
  font-family: 'Blinker', sans-serif;
  font-family: 'Electrolize', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Alex+Brush&family=Blinker:wght@100;300&family=Electrolize&display=swap');


::-webkit-scrollbar {
  width: 0px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.headerTitle{
  font-size: 5vw;
}
.headerSubTitle{
  font-size: 2vw;
}
.subTitle{
  font-size: 2vw;
  font-weight: 400;
}
.title{
  font-size: 3vw;
  font-weight: 500;
}
@media screen and (max-width: 640px) {
  .title{
    font-size: 6vw;
  }
  .headerTitle{
    font-size: 8vw;
  }
  .headerSubTitle{
    font-size: 3vw;
  }
  .subTitle{
    font-size: 4vw;
  }
}


.waviy span {
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  animation: flip 3s infinite;
  animation-delay: calc(.2s * var(--i))
}

@keyframes flip {
  0%,80% {
    transform: rotateY(360deg) 
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.skewed-3d {
  transform: perspective(1500px) rotateX(0) rotateY(32deg) rotateZ(0);
  transform-origin: center center;
  transform-style: preserve-3d;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.skewed-3d-left {
  transform: perspective(1500px) rotateX(0) rotateY(-32deg) rotateZ(0);
  transform-origin: center center;
  transform-style: preserve-3d;
}

/* For FAQ Section on Mobile-App-2 -- Panel style */
.accordion .a-container .a-panel {
  width: 100%;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  opacity: 0;
  height: auto;
  max-height: 0;
  overflow: hidden;
  padding: 0px 24px;
}

/* For FAQ Section on Mobile-App-2 -- Panel style when active */
.accordion .a-container.active .a-panel {
  padding: 0 24px 24px 24px;
  opacity: 1;
  height: auto;
  max-height: 500px;
}

.reveal{
  position: relative;
  transform: translateY(150px);
  opacity: 0;
  transition: 1s all ease;
}

.fadeIn{
  opacity: 0;
  transition:  all 2s ease;
}

.fadeIn.active{
  opacity: 1;
}

.reveal.active{
  transform: translateY(0);
  opacity: 1;
}
@keyframes flip2{
  0%   {transform: rotateY(0deg) }
  50%  {transform: rotateY(180deg) }
  100% {transform: rotateY(0deg) }
}

.flip{
  animation-name: flip2;
  animation-duration: 1s;
}
